/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import {
  Autocomplete,
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "utils/DataTable";
import FormField from "utils/FormField";

// Data
import {
  fetch,
  patch,
  download,
  field,
  dropdownRole,
  dropdownMembership,
  post,
} from "layouts/applications/data-users/data/tableData";
import ExcelExport from "utils/ExcelExport";
import useLocalStorage from "services/useLocalstorage";
import moment from "moment";

function DataUsers() {
  const { getItem } = useLocalStorage();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [open, setOpen] = useState(false);
  const [param, setParam] = useState({});
  const [action, setAction] = useState("");

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [roleOpt, setRoleOpt] = useState([]);
  const [membership, setMembership] = useState("");
  const [membershipOpt, setMembershipOpt] = useState([]);
  const [membershipState, setMembershipState] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  // console.log(data);

  const genderOpt = [
    { label: "Laki-laki", id: 1 },
    { label: "Perempuan", id: 2 },
  ];

  const clearForm = () => {
    setFullname("");
    setEmail("");
    setPassword("");
    setRole("");
    setMembership("");
    setPhone("");
    setBirthDate("");
    setGender("");
    setErrMsg("");
    setMembershipState(true);
  };

  const setParamOpenDialog = (value) => {
    setParam(value);
    clearForm();
    setAction("Edit");
    setFullname(value.detail.fullname);
    setEmail(value.detail.email);
    setRole({ label: value.detail.role, id: value.detail.roleId });
    setMembership({ label: value.detail.membership, id: value.detail.membershipId });
    setMembershipState(value.detail.role !== "Member");
    if (value.detail.gender) {
      setGender({
        label: value.detail.gender === "1" ? "Laki-laki" : "Perempuan",
        id: value.detail.gender,
      });
    }
    setPhone(value.detail.phone);
    setBirthDate(value.detail.birthDate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    clearForm();
    setOpen(true);
    setAction("Add");
  };

  const handleSave = async () => {
    let result = {};
    if (action === "Add") {
      const body = {
        id: uuidv4(),
        fullname,
        email,
        password,
        roleId: role.id,
      };
      if (role.label === "Member") {
        body.membershipId = membership.id;
      } else {
        body.membershipId = null;
      }
      if (gender && gender.id) {
        body.gender = gender.id.toString();
      }
      if (phone) {
        body.phone = phone;
      }
      if (birthDate) {
        body.birthDate = birthDate;
      }
      result = await post(action, { body });
    } else {
      const body = {
        id: param.id,
        fullname,
        email,
        roleId: role.id,
      };
      if (role.label === "Member") {
        body.membershipId = membership.id;
        body.membershipValidUntil = null;
      } else {
        body.membershipId = null;
        body.membershipValidUntil = null;
      }
      body.gender = gender && gender.id ? gender.id.toString() : null;
      body.phone = phone && phone.length ? phone : null;
      body.birthDate = birthDate && birthDate.length ? birthDate : null;
      result = await patch(param.id, body);
    }
    if (!result.status) {
      setErrMsg(result.message);
      return;
    }
    setFetchTrigger((val) => val + 1);
    handleClose();
  };

  const validate = async () => {
    setErrMsg("");
    if (fullname === "") {
      setErrMsg("Fullname harus diisi.");
      return;
    }

    if (email === "") {
      setErrMsg("Email harus diisi");
      return;
    }

    if (password === "" && action === "Add") {
      setErrMsg("Password harus diisi");
      return;
    }

    if (!role || role === "") {
      setErrMsg("Role harus diisi");
      return;
    }

    if (role.label === "Member" && (!membership || membership === "")) {
      setErrMsg("Membership harus diisi");
      return;
    }

    await handleSave();
  };

  const columns = useMemo(() => field({ setParamOpenDialog }), []);
  const fetchData = useCallback(async ({ pageIndex, pageSize, globalFilter }) => {
    setLoading(true);

    const skip = pageSize * pageIndex;
    const limit = skip + pageSize;
    const result = await fetch({ skip, limit }, globalFilter);
    setData(
      result.data.map((el) => ({
        ...el,
        action: {
          id: el.id,
          detail: {
            fullname: el.fullname,
            email: el.email,
            roleId: el.roleId,
            role: el.role.name,
            membershipId: el.membershipId,
            membership: el.membership?.name ?? "",
            gender: el.gender,
            birthDate: el.birthDate ? moment(el.birthDate).format("YYYY-MM-DD") : "",
            phone: el.phone,
          },
        },
      }))
    );
    setTotal(result.total);
    setPageCount(Math.ceil(result.total / pageSize));
    setLoading(false);
  }, []);

  useEffect(async () => {
    const resMembership = await dropdownMembership();
    const resRole = await dropdownRole();
    setRoleOpt(
      resRole.data.map((el) => ({
        label: el.name,
        id: el.id,
      }))
    );
    setMembershipOpt(
      resMembership.data.map((el) => ({
        label: el.name,
        id: el.id,
      }))
    );
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                User Management
              </MDTypography>
              <MDTypography variant="button" color="text">
                List of All User.
              </MDTypography>
            </MDBox>
            <MDBox width="12rem" ml="auto">
              <ExcelExport download={download} fileName="Excel Export User" />
            </MDBox>
          </MDBox>
          {getItem("isOwner") && (
            <MDBox p={3} lineHeight={1}>
              <Grid item xs={6} lg={2} container>
                <MDButton onClick={handleOpen} variant="gradient" color="info" fullWidth>
                  Add User
                </MDButton>
              </Grid>
            </MDBox>
          )}
          <DataTable
            canSearch
            columns={columns}
            data={data}
            total={total}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            refreshTrigger={fetchTrigger}
          />
          <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle>{action} User</DialogTitle>
            <DialogContent>
              <Stack direction="column" spacing={3}>
                <DialogContentText>Please fill all field to continue</DialogContentText>
                <MDInput
                  autoFocus
                  id="fullname"
                  label="Name Field *"
                  fullWidth
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
                <MDInput
                  id="email"
                  label="Email Field *"
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {action === "Add" && (
                  <MDInput
                    id="password"
                    label="Password Field *"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                )}
                <Autocomplete
                  id="role"
                  value={role}
                  onChange={(event, newValue) => {
                    setRole(newValue);
                    setMembership(null);
                    setMembershipState(newValue?.label !== "Member");
                  }}
                  options={roleOpt}
                  renderInput={(params) => <FormField {...params} label="Role Field *" />}
                />
                <Autocomplete
                  id="membership"
                  value={membership}
                  onChange={(event, newValue) => {
                    setMembership(newValue);
                  }}
                  options={membershipOpt}
                  renderInput={(params) => (
                    <FormField {...params} disabled={membershipState} label="Membership Field *" />
                  )}
                />
                <MDInput
                  id="phone"
                  label="Phone Field"
                  fullWidth
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <MDDatePicker
                  input={{
                    id: "birthDate",
                    label: "Birth Date Field",
                    fullWidth: true,
                    InputLabelProps: { shrink: true },
                  }}
                  options={{
                    dateFormat: "Y-m-d",
                    onChange: (selectedDates, dateStr, instance) => {
                      setBirthDate(dateStr);
                    },
                  }}
                  value={birthDate}
                />
                <Autocomplete
                  id="gender"
                  value={gender}
                  onChange={(event, newValue) => {
                    setGender(newValue);
                  }}
                  options={genderOpt}
                  renderInput={(params) => <FormField {...params} label="Gender Field" />}
                />
                <MDBox mb={2}>
                  <MDTypography variant="h4" fontWeight="medium" color="error" mt={1}>
                    {errMsg}
                  </MDTypography>
                </MDBox>
              </Stack>
            </DialogContent>
            <DialogActions>
              <MDButton variant="text" color="error" onClick={handleClose}>
                Cancel
              </MDButton>
              <MDButton variant="text" color="info" onClick={validate}>
                Save
              </MDButton>
            </DialogActions>
          </Dialog>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataUsers;
