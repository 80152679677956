/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useCallback, useEffect, useMemo } from "react";

import CurrencyFormat from "react-currency-format";

// @mui material components
import {
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "utils/DataTable";

// Data
import { fetch, patch, field } from "layouts/applications/data-membership/data/tableData";

function DataMembership() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [open, setOpen] = useState(false);
  const [param, setParam] = useState({});

  const [pricing, setPricing] = useState(0);

  const setParamOpenDialog = (value) => {
    setParam(value);
    setPricing(value.detail.pricing);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const body = {
      pricing: parseInt(pricing, 10),
    };
    const result = await patch(param.id, body);
    setFetchTrigger((val) => val + 1);
    handleClose();
  };

  const columns = useMemo(() => field({ setParamOpenDialog }), []);
  const fetchData = useCallback(async ({ pageIndex, pageSize, globalFilter }) => {
    setLoading(true);

    const skip = pageSize * pageIndex;
    const limit = skip + pageSize;
    const result = await fetch({ skip, limit }, globalFilter);
    setData(
      result.data.map((el) => ({
        ...el,
        action: {
          id: el.id,
          detail: {
            isActive: el.isActive,
            pricing: el.pricing,
          },
        },
      }))
    );
    setTotal(result.total);
    setPageCount(Math.ceil(result.total / pageSize));
    setLoading(false);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Membership Management
            </MDTypography>
            <MDTypography variant="button" color="text">
              List of All Membership.
            </MDTypography>
          </MDBox>
          <DataTable
            canSearch
            columns={columns}
            data={data}
            total={total}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            refreshTrigger={fetchTrigger}
          />
          <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle>Edit Membership</DialogTitle>
            <DialogContent>
              <Stack direction="column" spacing={3}>
                <MDBox pt={1} pb={1}>
                  <CurrencyFormat
                    value={pricing}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="Rp"
                    customInput={MDInput}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      // formattedValue = $2,223
                      // value ie, 2223
                      setPricing(value);
                    }}
                    autoFocus
                    id="pricing"
                    label="Pricing"
                    fullWidth
                  />
                </MDBox>
              </Stack>
            </DialogContent>
            <DialogActions>
              <MDButton variant="text" color="error" onClick={handleClose}>
                Cancel
              </MDButton>
              <MDButton variant="text" color="info" disabled={!pricing} onClick={handleSave}>
                Save
              </MDButton>
            </DialogActions>
          </Dialog>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataMembership;
