/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Stack } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

function Header({ orderId, invoiceNumber, status, deliveryStatus, color }) {
  return (
    <MDBox display="flex" alignItems="center" columnGap={10}>
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Order Details
          </MDTypography>
        </MDBox>
        <MDTypography component="p" variant="button" color="text">
          Order no. <b>{orderId}</b> from{" "}
          <b>{moment(invoiceNumber.split("/")[1]).format("DD MMM YYYY")}</b>
        </MDTypography>
        <MDTypography component="p" variant="button" fontWeight="regular" color="text">
          Invoice: <b>{invoiceNumber}</b>
        </MDTypography>
      </MDBox>
      <MDBox>
        <Stack direction="row" spacing={10}>
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              Status
            </MDTypography>
            <MDBadge variant="gradient" color={color} size="xs" badgeContent={status} container />
          </MDBox>
          {deliveryStatus && (
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium">
                Delivery Status
              </MDTypography>
              <MDBadge
                variant="gradient"
                color="info"
                size="xs"
                badgeContent={deliveryStatus}
                container
              />
            </MDBox>
          )}
        </Stack>
      </MDBox>
    </MDBox>
  );
}

export default Header;
