/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
import { htmlEditButton } from "quill-html-edit-button";
// react-quill styles
import "react-quill/dist/quill.snow.css";
// Custom styles for the MDEditor
import MDEditorRoot from "components/MDEditor/MDEditorRoot";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

Quill.register({
  "modules/ImageResize": ImageResize,
  "modules/htmlEditButton": htmlEditButton,
});
function MDEditor(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["clean"],
    ],
    ImageResize: true,
    htmlEditButton: {},
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <ReactQuill theme="snow" modules={modules} formats={formats} {...props} />
    </MDEditorRoot>
  );
}

export default MDEditor;
