/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid } from "@mui/material";

import { getFile } from "services/APIServices";

import CurrencyCell from "utils/CurrencyCell";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import moment from "moment";

// Images
import orderImage from "assets/images/product-12.jpg";

function OrderInfo({ details }) {
  return (
    <Grid container spacing={3} alignItems="center">
      {details.map((item, index) => (
        <Grid item xs={6} key={item.id}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDAvatar size="xxl" src={getFile(item.images.frontImage)} alt={item.product.name} />
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="h6" fontWeight="medium">
                {item.product.name}
              </MDTypography>
              <MDBox>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Variant: {item.size.category.variant.name}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Color: {item.color.name}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Price: <CurrencyCell value={item.product.price} />
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Quantity: {item.qty}
                </MDTypography>
              </MDBox>
              {/* <MDBadge
                  variant="gradient"
                  color="success"
                  size="xs"
                  badgeContent="delivered"
                  container
                /> */}
            </MDBox>
          </MDBox>
        </Grid>
      ))}
    </Grid>
  );
}

export default OrderInfo;
