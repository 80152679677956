/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { ZoomIn } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";

const URL = "/products";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(
      `${URL}-panel?filter=${encodeURIComponent(JSON.stringify(filter))}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      }
    )
    .then((res) => res.data);

  return result;
};

const post = async (id, body) => {
  const result = await api
    .post(`${URL}/${id}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "category",
    accessor: "action.detail.category",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "created by",
    accessor: "action.detail.creator",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "privacy",
    accessor: "privacy",
    Cell: ({ value }) => <DefaultCell value={value === "Private" ? "Custom" : value ?? ""} />,
  },
  {
    Header: "status",
    accessor: "flow",
    Cell: ({ value }) => {
      let status;

      if (value.val === "Publish") {
        status = <StatusCell icon="done" color="success" status={value.val} />;
      } else if (value.val === "Draft") {
        status = (
          <StatusCell
            icon={!value.key || value.key === 0 ? "replay" : "done"}
            color={!value.key || value.key === 0 ? "warning" : "success"}
            status={!value.key || value.key === 0 ? "Waiting" : "Set"}
          />
        );
      } else {
        status = <StatusCell icon="close" color="error" status={value.val} />;
      }

      return status;
    },
  },
  {
    Header: "price",
    accessor: "price",
    Cell: ({ value }) => {
      if (!value || value === 0) {
        return <DefaultCell value="-" />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  {
    Header: "created date",
    accessor: "createdDt",
    Cell: ({ value }) => <DefaultCell value={value.split("T")[0]} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DefaultCell value={value.split("T")[0]} />,
  },
  {
    Header: "action",
    accessor: "action",
    width: "10%",
    Cell: ({ value }) => (
      <MDBox>
        <Tooltip title="Approve Request">
          <IconButton color="success" onClick={() => setParamOpenDialog(value)}>
            <ZoomIn />
          </IconButton>
        </Tooltip>
      </MDBox>
    ),
  },
];

export { fetch, post, field, URL };
