/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewProduct page components
import Page from "layouts/applications/static-page/components/Page";
import Banner from "layouts/applications/static-page/components/Banner";

function getSteps() {
  return ["1. Page", "2. Banner"];
}

// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 0:
//       return <ProductInfo />;
//     case 1:
//       return <Media />;
//     default:
//       return null;
//   }
// }
function NewProduct() {
  const [activeStep, setActiveStep] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setTrigger((val) => val + 1);
  };
  const handleBack = () => {
    setTrigger((val) => val - 1);
    setActiveStep((val) => val - 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {/* {getStepContent(activeStep)} */}
                  {activeStep === 0 && <Page trigger={trigger} nextStep={setActiveStep} />}
                  {activeStep === 1 && <Banner />}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    {!isLastStep && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={!isLastStep ? handleNext : undefined}
                      >
                        next
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProduct;
