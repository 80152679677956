/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { api } from "services/APIServices";

const NEWSLETTER_URL = "/subscribes";
const dataNewsletter = () => {
  const [newsletter, setNewsletter] = useState([]);
  const [newsletterForRender, setNewsletterForRender] = useState([]);

  const filter = {
    offset: 0,
    limit: 100,
    skip: 0,
    order: "updatedDt desc",
    // where: {
    //   additionalProp1: {},
    // },
  };

  const fetchNewsletter = async () => {
    await api
      .get(`${NEWSLETTER_URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
      .then((res) => {
        setNewsletter(res.data.data);
      });
  };

  useEffect(() => {
    fetchNewsletter();
  }, []);

  useEffect(() => {
    const newsletterArray = JSON.parse(JSON.stringify(newsletter));

    const newsletterData = newsletterArray.map((item, index) => {
      const newNewsletter = item;
      // newProduct.brandEmail = item.brand.email;
      // newProduct.productCategory = item.availableSizes[0].size.category.name;
      // const newCreatedDt = item.createdDt.split("T")[0];
      // const newUpdatedDt = item.updatedDt.split("T")[0];
      // newProduct.createdDt = newCreatedDt;
      // newProduct.updatedDt = newUpdatedDt;
      // newProduct.action = (
      //   <MDBox>
      //     {newProduct.privacy === "Submit" && newProduct.flow !== "Hidden" && (
      //       <>
      //         <Tooltip title="Approve">
      //           <IconButton color="success" onClick={() => handleDialog(item.id, "publish")}>
      //             <DoneIcon />
      //           </IconButton>
      //         </Tooltip>
      //         <Tooltip title="Reject">
      //           <IconButton color="error" onClick={() => handleDialog(item.id, "reject")}>
      //             <CloseIcon />
      //           </IconButton>
      //         </Tooltip>
      //       </>
      //     )}
      //     {newProduct.flow !== "Hidden" && (
      //       <Tooltip title="Delete">
      //         <IconButton color="primary" onClick={() => handleDialog(item.id, "delete")}>
      //           <DeleteIcon />
      //         </IconButton>
      //       </Tooltip>
      //     )}
      //   </MDBox>
      // );
      return newNewsletter;
    });
    setNewsletterForRender(newsletterData);
  }, [newsletter]);

  const field = [
    { Header: "email", accessor: "email" },
    { Header: "updated date", accessor: "updatedDt" },
    // { Header: "action", accessor: "action" },
  ];

  return {
    columns: field,
    rows: newsletterForRender,
  };
};

export default dataNewsletter;
