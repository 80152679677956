/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/material";

import CurrencyFormat from "react-currency-format";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// OrderDetails page components
import Header from "utils/OrderDetails/components/Header";
import OrderInfo from "utils/OrderDetails/components/OrderInfo";
import OrdersOverview from "utils/OrderDetails/components/OrdersOverview";
import OrderSummary from "utils/OrderDetails/components/OrderSummary";

function OrderDetails({ data, open }) {
  const { id, detail } = data;
  const getColor = (status) => {
    let color;
    if (status === "Finished") {
      color = "success";
    } else if (status === "Process Packaging") {
      color = "info";
    } else if (status === "On Delivery") {
      color = "dark";
    } else if (status === "Waiting Payment") {
      color = "warning";
    } else {
      color = "error";
    }

    return color;
  };

  const showButton = (status) => {
    let show;
    if (status === "Process Packaging") {
      show = true;
    } else {
      show = false;
    }

    return show;
  };

  const calculate = (delivery, amount) => parseInt(delivery, 10) + parseInt(amount, 10);
  return (
    <Stack direction="column" spacing={3}>
      <MDBox pt={3} px={3}>
        <Header
          orderId={id}
          invoiceNumber={detail.invoiceNumber}
          createdDt={detail.createdDt}
          status={detail.status}
          deliveryStatus={detail.deliveryStatus}
          color={getColor(detail.status)}
        />
      </MDBox>
      <MDBox pb={3} px={3}>
        <Divider />
        <MDBox mb={3}>
          <OrderInfo details={detail.orderDetails} />
        </MDBox>
        <Divider />
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <OrderSummary
              delivery={detail.delivery}
              amount={detail.amount}
              total={calculate(detail.delivery, detail.amount)}
              adminClaim={detail.adminClaim}
              discount={detail.discount}
            />
          </Grid>
          <Grid item xs={4}>
            <MDBox mb={2}>
              <MDTypography variant="h6" fontWeight="medium">
                &nbsp;
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Admin Claim:
              </MDTypography>
              <MDBox ml={1}>
                <MDTypography variant="body2" fontWeight="medium">
                  {!detail.adminClaim || detail.adminClaim === 0 ? (
                    "-"
                  ) : (
                    <CurrencyFormat
                      value={detail.adminClaim}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="Rp"
                    />
                  )}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction="row"
            alignItems="flex-end"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <OrdersOverview orderId={id} button={showButton(detail.status)} process={open} />
          </Grid>
        </Grid>
      </MDBox>
    </Stack>
  );
}

export default OrderDetails;
