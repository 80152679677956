/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";
import { baseUrlApi } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

const URL = "/banner-upload";
function Banner() {
  const { getItem } = useLocalStorage();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MDBox>
      <MDTypography variant="h5">Banner</MDTypography>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Banner Login/Register
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                url: `${baseUrlApi}${URL}`,
                headers: { Authorization: `Bearer ${getItem("token")}` },
                params: { sysCat: "frontend", sysSubCat: "static", sysCd: "banner_login" },
                acceptedFiles: "image/jpeg,image/png",
              }}
            />
          ),
          []
        )}
      </MDBox>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Banner Homepage 1
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                url: `${baseUrlApi}${URL}`,
                headers: { Authorization: `Bearer ${getItem("token")}` },
                params: { sysCat: "frontend", sysSubCat: "static", sysCd: "home_banner_1" },
                acceptedFiles: "image/jpeg,image/png",
              }}
            />
          ),
          []
        )}
      </MDBox>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Banner Homepage 2
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                url: `${baseUrlApi}${URL}`,
                headers: { Authorization: `Bearer ${getItem("token")}` },
                params: { sysCat: "frontend", sysSubCat: "static", sysCd: "home_banner_2" },
                acceptedFiles: "image/jpeg,image/png",
              }}
            />
          ),
          []
        )}
      </MDBox>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Preview Feature Design
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                url: `${baseUrlApi}${URL}`,
                headers: { Authorization: `Bearer ${getItem("token")}` },
                params: { sysCat: "frontend", sysSubCat: "static", sysCd: "image_feature" },
                acceptedFiles: "image/jpeg,image/png",
              }}
            />
          ),
          []
        )}
      </MDBox>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Banner Footer
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                url: `${baseUrlApi}${URL}`,
                headers: { Authorization: `Bearer ${getItem("token")}` },
                params: { sysCat: "frontend", sysSubCat: "static", sysCd: "banner_footer" },
                acceptedFiles: "image/jpeg,image/png",
              }}
            />
          ),
          []
        )}
      </MDBox>
    </MDBox>
  );
}

export default Banner;
