/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/applications/static-page/components/FormField";

import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

const URL = "/system-master";
function Page({ trigger, nextStep }) {
  const { getItem } = useLocalStorage();
  const [aboutValue, setAboutValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const [contactValue, setContactValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const [faqValue, setFaqValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const [panduanValue, setPanduanValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const [privacyValue, setPrivacyValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const [termValue, setTermValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const [featureValue, setFeatureValue] = useState(
    "<p>Some initial <strong>bold</strong> text</p><br><br><br><br>"
  );
  const filter = {
    where: {
      sysCat: "frontend",
      sysSubCat: "static",
    },
    fields: {
      sysCd: true,
      sysValue: true,
    },
  };

  const generateParam = () => [
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "about",
      sysValue: aboutValue,
    },
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "contact",
      sysValue: contactValue,
    },
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "designfeature",
      sysValue: featureValue,
    },
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "faq",
      sysValue: faqValue,
    },
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "panduan",
      sysValue: panduanValue,
    },
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "privacy",
      sysValue: privacyValue,
    },
    {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "term",
      sysValue: termValue,
    },
  ];

  const updateContent = async () => {
    await api
      .patch(`${URL}/bulk`, JSON.stringify(generateParam()), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      })
      .then((res) => {
        const result = res.data.data;
        nextStep((val) => val + 1);
      });
  };

  const fetch = async () => {
    await api
      .get(`${URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const result = res.data.data;
        for (let index = 0; index < result.length; index += 1) {
          const element = result[index];
          switch (element.sysCd) {
            case "about":
              setAboutValue(element.sysValue);
              break;
            case "contact":
              setContactValue(element.sysValue);
              break;
            case "designfeature":
              setFeatureValue(element.sysValue);
              break;
            case "faq":
              setFaqValue(element.sysValue);
              break;
            case "panduan":
              setPanduanValue(element.sysValue);
              break;
            case "privacy":
              setPrivacyValue(element.sysValue);
              break;
            case "term":
              setTermValue(element.sysValue);
              break;
            default:
              break;
          }
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch();
  }, []);

  useEffect(() => {
    if (trigger) {
      updateContent();
    }
  }, [trigger]);
  return (
    <MDBox>
      <MDTypography variant="h5">Page</MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                About Us
              </MDTypography>
            </MDBox>
            <MDEditor value={aboutValue} onChange={setAboutValue} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Contact Us
              </MDTypography>
            </MDBox>
            <MDEditor value={contactValue} onChange={setContactValue} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                FAQ
              </MDTypography>
            </MDBox>
            <MDEditor value={faqValue} onChange={setFaqValue} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Panduan Desain
              </MDTypography>
            </MDBox>
            <MDEditor value={panduanValue} onChange={setPanduanValue} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Privacy
              </MDTypography>
            </MDBox>
            <MDEditor value={privacyValue} onChange={setPrivacyValue} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Term & Condition
              </MDTypography>
            </MDBox>
            <MDEditor value={termValue} onChange={setTermValue} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Design Feature Overview
              </MDTypography>
            </MDBox>
            <MDEditor value={featureValue} onChange={setFeatureValue} />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Page;
