/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useCallback, useEffect, useMemo } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "utils/DataTable";

import OrderDetails from "utils/OrderDetails";
import FormField from "utils/FormField";

// Data
import { fetch, field, biteship, download } from "layouts/applications/data-order/data/tableData";
import ExcelExport from "utils/ExcelExport";

function DataOrder() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [open, setOpen] = useState(false);
  const [param, setParam] = useState({});

  const [openAction, setOpenAction] = useState(false);
  const [id, setId] = useState(null);

  const setParamOpenDialog = (value) => {
    setParam(value);
    setOpen(true);
  };

  const setIdOpenAction = (value) => {
    setId(value);
    setOpenAction(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAction = () => {
    setOpenAction(false);
  };

  const handleSave = async () => {
    const result = await biteship(id);
    handleCloseAction();
    handleClose();
    setFetchTrigger((val) => val + 1);
  };

  const columns = useMemo(() => field({ setParamOpenDialog }), []);
  const fetchData = useCallback(async ({ pageIndex, pageSize, globalFilter }) => {
    setLoading(true);
    const skip = pageSize * pageIndex;
    const limit = skip + pageSize;
    const result = await fetch({ skip, limit }, globalFilter);
    setData(
      result.data.map((el) => ({
        ...el,
        total: (el.amount ?? 0) + (el.deliveryFee ?? 0),
        action: {
          id: el.id,
          detail: {
            status: el.status,
            delivery: el.deliveryFee ?? 0,
            deliveryStatus: el.deliveryStatus,
            amount: el.amount ?? 0,
            invoiceNumber: el.invoiceNumber,
            createdDt: el.createdDt,
            orderDetails: el.orderDetails,
            adminClaim: el.adminClaim,
            discount: el.discount,
          },
        },
      }))
    );
    setTotal(result.total);
    setPageCount(Math.ceil(result.total / pageSize));
    setLoading(false);
  }, []);

  const orderStatusOpt = [
    "All",
    "Waiting Payment",
    "Expired",
    "Process Packaging",
    "On Delivery",
    "Finished",
  ];

  const [orderStatus, setOrderStatus] = useState(orderStatusOpt[0]);

  const generateParam = () => {
    let result = null;

    if (orderStatus !== "All") {
      result = `&orderStatus=${orderStatus}`;
    }

    return result;
  };
  // useEffect(() => {
  //   console.log(param);
  // }, [param]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Order Management
              </MDTypography>
              <MDTypography variant="button" color="text">
                List of All Order.
              </MDTypography>
            </MDBox>
            <MDBox width="12rem" ml="auto">
              <ExcelExport
                download={download}
                fileName="Excel Export Order"
                param={generateParam()}
              >
                <MDBox pt={1} pb={1}>
                  <Autocomplete
                    id="order-status"
                    value={orderStatus}
                    onChange={(event, newValue) => {
                      setOrderStatus(newValue);
                    }}
                    options={orderStatusOpt}
                    renderInput={(params) => <FormField {...params} label="Status" />}
                  />
                </MDBox>
              </ExcelExport>
            </MDBox>
          </MDBox>
          <DataTable
            canSearch
            columns={columns}
            data={data}
            total={total}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            refreshTrigger={fetchTrigger}
          />
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <OrderDetails data={param} open={setIdOpenAction} />
            {/* <DialogTitle>Order Details</DialogTitle>
            <DialogContent>
              <DialogContentText>{JSON.stringify(param, null, 2)}</DialogContentText>
              <OrderDetails />
            </DialogContent> */}
          </Dialog>
          <Dialog fullWidth maxWidth="xs" open={openAction} onClose={handleCloseAction}>
            <DialogTitle>Process Order</DialogTitle>
            <DialogContent>
              <Stack direction="column" spacing={3}>
                <MDBox pt={1} pb={1}>
                  <DialogContentText>Apakah Anda yakin melakukan proses ini?</DialogContentText>
                </MDBox>
              </Stack>
              <DialogActions>
                <MDButton variant="text" color="error" onClick={handleCloseAction}>
                  Cancel
                </MDButton>
                <MDButton variant="text" color="info" onClick={handleSave}>
                  Confirm
                </MDButton>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataOrder;
