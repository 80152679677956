/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import {
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataRole from "layouts/applications/data-role/data/dataTableData";

function DataRoles() {
  const { getItem } = useLocalStorage();
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [param, setParam] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [detail, setDetail] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleTrigger = (action) => {
    setParam({
      id: detail.id,
      body: {
        name,
        description,
      },
      action,
    });
    handleCloseDetail();
    handleClose();
    setTrigger((val) => val + 1);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Role Management
            </MDTypography>
            <MDTypography variant="button" color="text">
              List of All Roles.
            </MDTypography>
          </MDBox>
          {getItem("isOwner") && (
            <MDBox p={3} lineHeight={1}>
              <Grid item xs={6} lg={2} container>
                <MDButton onClick={handleOpen} variant="gradient" color="info" fullWidth>
                  Add Role
                </MDButton>
              </Grid>
            </MDBox>
          )}
          <DataTable table={dataRole({ setOpenDetail, setDetail, trigger, param })} canSearch />
        </Card>
        <Dialog open={openDetail} onClose={handleCloseDetail}>
          <DialogTitle>{detail && detail.title} Role</DialogTitle>
          <DialogContent>
            <DialogContentText>Apakah Anda yakin melakukan hal ini?</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <MDButton variant="text" color="error" onClick={handleCloseDetail}>
              Cancel
            </MDButton>
            <MDButton variant="text" color="info" onClick={() => handleTrigger("toggle")}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
          <DialogTitle>Add Role</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={3}>
              <DialogContentText>Please fill all field to continue</DialogContentText>
              <MDInput
                autoFocus
                id="name"
                label="Name Field"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <MDInput
                id="description"
                label="Description Field"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <MDButton variant="text" color="error" onClick={handleClose}>
              Cancel
            </MDButton>
            <MDButton variant="text" color="info" onClick={() => handleTrigger("add")}>
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataRoles;
