/* eslint-disable react/prop-types */
import { useState } from "react";
// @mui material components
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from "@mui/material";

import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";
import moment from "moment";

function ExcelExport({ download, fileName, param, children }) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [openExport, setOpenExport] = useState(false);
  const [period, setPeriod] = useState(
    `${moment().startOf("month").format("YYYY-MM-DD")} to ${moment().format("YYYY-MM-DD")}`
  );
  const [noData, setNoData] = useState(false);

  const handleOpen = () => {
    setOpenExport(true);
  };

  const handleClose = () => {
    setOpenExport(false);
    setNoData(false);
  };

  const generateParam = () => {
    const range = period.split("to");
    const periodFrom = range[0].trim();
    const periodTo = moment(range.length > 1 ? range[1].trim() : range[0].trim())
      .add(1, "d")
      .format("YYYY-MM-DD");

    let result = `periodFrom=${periodFrom}&periodTo=${periodTo}`;

    if (param) {
      result += param;
    }
    return result;
  };

  const exportToExcel = async () => {
    setNoData(false);
    const result = await download(generateParam());
    if (result.data.length > 0) {
      const ws = XLSX.utils.json_to_sheet(result.data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, `${fileName}_${moment().valueOf()}${fileExtension}`);
      handleClose();
    } else {
      setNoData(true);
    }
  };

  return (
    <>
      <MDButton variant="gradient" onClick={handleOpen} color="primary" fullWidth>
        Export To Excel
      </MDButton>
      <Dialog fullWidth maxWidth="sm" open={openExport} onClose={handleClose}>
        <DialogTitle>Select Filter Export</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3}>
            <MDBox pt={1} pb={1}>
              <MDDatePicker
                input={{
                  id: "periodRange",
                  label: "Period Range",
                  placeholder: "Select Period Range",
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  onChange: (selectedDates, dateStr, instance) => {
                    setPeriod(dateStr);
                  },
                }}
                value={period}
              />
            </MDBox>
            {children}
            {noData && <MDAlert color="error">No Data to Export</MDAlert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="error" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton variant="text" color="info" onClick={exportToExcel}>
            Export
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExcelExport;
