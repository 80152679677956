/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

function StatisticsCard({ title, children, percentage, dropdown, icon }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card>
      <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
        <Grid container>
          <Grid item xs={7}>
            <MDBox display="flex" px={1} pt={1}>
              {icon.component && (
                <MDBox
                  width="4rem"
                  height="4rem"
                  bgColor={icon.color || "info"}
                  variant="gradient"
                  coloredShadow={icon.color || "info"}
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  mt={-5}
                  mr={2}
                >
                  <Icon fontSize="medium">{icon.component}</Icon>
                </MDBox>
              )}
              <MDBox mt={icon.component ? -2 : 0}>
                <MDBox mb={0.5} lineHeight={1}>
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    color="text"
                    textTransform="capitalize"
                  >
                    {title}
                  </MDTypography>
                </MDBox>
                <MDBox lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="bold">
                    {children}
                  </MDTypography>
                  {percentage && (
                    <MDTypography variant="button" fontWeight="bold" color={percentage.color}>
                      {percentage.value}&nbsp;
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color={darkMode ? "text" : "secondary"}
                      >
                        {percentage.label}
                      </MDTypography>
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={5}>
            {dropdown && (
              <MDBox width="100%" textAlign="right" lineHeight={1}>
                <MDTypography
                  variant="caption"
                  color="secondary"
                  fontWeight="regular"
                  sx={{ cursor: "pointer" }}
                  onClick={dropdown.action}
                >
                  {dropdown.value}
                </MDTypography>
                {dropdown.menu}
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of StatisticsCard
StatisticsCard.defaultProps = {
  percentage: false,
  dropdown: false,
  icon: { color: "info", component: "" },
};

// Typechecking props for the StatisticsCard
StatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  percentage: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "white",
      ]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ]),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
      value: PropTypes.string,
    }),
  ]),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
};

export default StatisticsCard;
