/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const dataTableData = {
  columns: [
    { Header: "Action", accessor: "name", width: "20%" },
    { Header: "#ID", accessor: "role", width: "25%" },
    { Header: "Filename", accessor: "email" },
    { Header: "Size", accessor: "phone", width: "7%" },
    { Header: "created date", accessor: "startDate" },
  ],

  rows: [
    {
      name: "Restore",
      role: "CRT-BACKUP-1",
      email: "autobackup_2023-05-16_00-00_Createe.sql.zip",
      phone: "102,1 KB",
      startDate: "4/11/2021",
    },

    {
      name: "Restore",
      role: "CRT-BACKUP-2",
      email: "autobackup_2023-05-17_00-00_Createe.sql.zip",
      phone: "103,1 KB",
      startDate: "8/2/2021",
    },
    {
      name: "Restore",
      role: "CRT-BACKUP-3",
      email: "autobackup_2023-05-18_00-00_Createe.sql.zip",
      phone: "122,13 KB",
      startDate: "4/21/2021",
    },
  ],
};

export default dataTableData;
