/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import ProductCell from "utils/ProductCell";

const URL = "/panel-dashboard";
const URL_FEATURED = "/panel-top-selling";
const { getItem } = useLocalStorage();

const fetchMonthly = async () => {
  const result = await api
    .get(`${URL}-monthly`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const fetchYearly = async () => {
  const result = await api
    .get(`${URL}-yearly`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const fetchTopSelling = async (filter) => {
  const result = await api
    .get(`${URL_FEATURED}?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = () => [
  {
    Header: "product",
    accessor: "product",
    width: "55%",
    Cell: ({ value }) => (
      <ProductCell image={value.image} name={value.name} brand={value.brand} orders={value.order} />
    ),
  },
  {
    Header: "variant",
    accessor: "detail.variant",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "category",
    accessor: "detail.category",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    align: "center",
    Cell: ({ value }) => <DefaultCell value={value.split("T")[0]} />,
  },
];

const lineChartData = (data) => ({
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Admin Claim",
      color: "info",
      data,
    },
  ],
});

export { fetchMonthly, fetchYearly, fetchTopSelling, field, lineChartData };
