/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Role Page: https://www.creative-tim.com/role/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { Delete, Check } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

const ROLE_URL = "/roles";
const dataRole = ({ setOpenDetail, setDetail, trigger, param }) => {
  const { getItem } = useLocalStorage();
  const [role, setRole] = useState([]);
  const [roleForRender, setRoleForRender] = useState([]);

  const filter = {
    offset: 0,
    limit: 100,
    skip: 0,
    order: "updatedDt desc",
    // where: {
    //   additionalProp1: {},
    // },
  };

  const fetchRole = async () => {
    await api
      .get(`${ROLE_URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      })
      .then((res) => {
        setRole(res.data.data);
      });
  };

  const actionProduct = async () => {
    const { id, body, action } = param;
    switch (action) {
      case "toggle":
        await api
          .delete(`${ROLE_URL}/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setRole([]);
              fetchRole();
            }
          });
        break;

      default:
        await api
          .post(`${ROLE_URL}`, JSON.stringify(body), {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setRole([]);
              fetchRole();
            }
          });
        break;
    }
  };

  const handleDialog = (item, title) => {
    const { id } = item;
    setDetail({
      id,
      title,
    });
    setOpenDetail(true);
  };

  useEffect(() => {
    fetchRole();
  }, []);

  useEffect(() => {
    if (trigger) {
      actionProduct();
    }
  }, [trigger]);

  useEffect(() => {
    const roleArray = JSON.parse(JSON.stringify(role));

    const roleData = roleArray.map((item, index) => {
      const newRole = item;
      // newRole.brandEmail = item.brand.email;
      // newRole.roleCategory = item.availableSizes[0].size.category.name;
      const title = item.isActive ? "Inactivate" : "Activate";
      const color = item.isActive ? "error" : "success";
      const icon = item.isActive ? <Delete /> : <Check />;
      newRole.isActive = item.isActive ? "Active" : "Inactive";
      const newCreatedDt = item.createdDt.split("T")[0];
      const newUpdatedDt = item.updatedDt.split("T")[0];
      newRole.createdDt = newCreatedDt;
      newRole.updatedDt = newUpdatedDt;
      newRole.action = (
        <MDBox>
          <Tooltip title={title}>
            <IconButton color={color} onClick={() => handleDialog(item, title)}>
              {icon}
            </IconButton>
          </Tooltip>
        </MDBox>
      );
      return newRole;
    });
    setRoleForRender(roleData);
  }, [role]);

  const field = [
    { Header: "name", accessor: "name", width: "20%" },
    { Header: "description", accessor: "description", width: "25%" },
    { Header: "status", accessor: "isActive", width: "7%" },
    { Header: "created date", accessor: "createdDt" },
    { Header: "updated date", accessor: "updatedDt" },
    { Header: "action", accessor: "action" },
  ];

  return {
    columns: field,
    rows: roleForRender,
  };
};

export default dataRole;
