/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Currency plugin
import CurrencyFormat from "react-currency-format";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

function CurrencyCell({ value, isWeight }) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {isWeight ? (
        <CurrencyFormat
          value={value}
          displayType="text"
          decimalScale={2}
          renderText={(val) => `${val} gr`}
        />
      ) : (
        <CurrencyFormat
          value={value}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          prefix="Rp"
        />
      )}
    </MDTypography>
  );
}

// Setting default values for the props of DataTable
CurrencyCell.defaultProps = {
  isWeight: false,
};

// Typechecking props for the CurrencyCell
CurrencyCell.propTypes = {
  value: PropTypes.number.isRequired,
  isWeight: PropTypes.bool,
};

export default CurrencyCell;
