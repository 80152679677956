import axios from "axios";
import logo from "assets/images/logo-icon.png";

// const baseUrlApi = "http://localhost:3001";
// const baseUrlApi = "http://192.168.1.212:3001";
// const baseUrlApi = window.location.protocol + "//" + window.location.hostname + ":3001";
// const baseUrlApi = `https://apicreatee.verztec.co.id`;
const baseUrlApi = `https://api.ccreatee.com`;

const getFile = (src) => (src ? `${baseUrlApi}${src}` : logo);
const api = axios.create({
  baseURL: baseUrlApi,
});

export { api, getFile, baseUrlApi };
