/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import CurrencyFormat from "react-currency-format";

// @mui material components
import { Grid, Stack } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

function ProductInfo({ data, action }) {
  const badgeColor = () => {
    let color;

    if (data.privacy === "Private") {
      if (!data.price || data.price === 0) {
        color = "warning";
      } else {
        color = "success";
      }
    } else if (data.flow === "Publish") {
      color = "success";
    } else {
      color = "error";
    }

    return color;
  };

  const badgeContent = () => {
    let content;

    if (data.privacy === "Private") {
      if (!data.price || data.price === 0) {
        content = "Waiting";
      } else {
        content = "Set";
      }
    } else {
      content = data.flow;
    }

    return content;
  };
  return (
    <MDBox>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <img
            alt="Logo"
            src={data.logo}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={6}>
          <MDBox mb={1}>
            <MDTypography variant="h3" fontWeight="bold">
              {data.name}
            </MDTypography>
          </MDBox>
          <MDTypography variant="h4" color="text">
            {data.variant} - {data.category}
          </MDTypography>
          <MDBox mt={1}>
            <MDTypography variant="h6" fontWeight="medium">
              Price
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {data.price || data.price > 0 ? (
                <CurrencyFormat
                  value={data.price}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="Rp"
                />
              ) : (
                "-"
              )}
            </MDTypography>
          </MDBox>
          <MDBadge
            variant="contained"
            color={badgeColor()}
            badgeContent={badgeContent()}
            container
          />
        </Grid>
      </Grid>
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Description
        </MDTypography>
      </MDBox>
      <MDBox component="ul" m={0} pl={4} mb={2}>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
            {data.description}
          </MDTypography>
        </MDBox>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
            Color: {data.color}
          </MDTypography>
        </MDBox>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
            Size: {data.size}
          </MDTypography>
        </MDBox>
        {data.privacy === "Private" ? (
          <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
            <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
              Createe Custom Design
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
            <MDTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
              Brand: {data.brand}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <MDBox mt={3}>
        {data.privacy === "Private" && (
          <Stack direction="row" spacing={3}>
            <MDButton onClick={() => action("price")} variant="gradient" color="info" fullWidth>
              set price
            </MDButton>

            <MDButton onClick={() => action("reject")} variant="gradient" color="error" fullWidth>
              reject
            </MDButton>
          </Stack>
        )}
      </MDBox>
      <MDBox mt={3}>
        {data.flow === "Publish" && (
          <Stack direction="row" spacing={3}>
            <MDButton onClick={() => action("reject")} variant="gradient" color="error" fullWidth>
              reject
            </MDButton>

            {/* <MDButton onClick={() => action("model")} variant="gradient" color="info" fullWidth>
              upload model
            </MDButton> */}
          </Stack>
        )}
        {data.flow === "Waiting to Delete" && (
          <Stack direction="row" spacing={3}>
            <MDButton onClick={() => action("delete")} variant="gradient" color="error" fullWidth>
              delete
            </MDButton>
          </Stack>
        )}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the ProductInfo
ProductInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  action: PropTypes.func.isRequired,
};

export default ProductInfo;
