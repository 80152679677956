import { useNavigate } from "react-router-dom";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Container, Grid } from "@mui/material";

function ErrorPages() {
  const navigate = useNavigate();
  const handleRoute = (e) => {
    e.preventDefault();
    navigate("/", { replace: true });
  };
  return (
    <PageLayout background="white">
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid xs={6}>
              <MDTypography variant="h1">404</MDTypography>
              <MDTypography variant="h6">The page you’re looking for doesn’t exist.</MDTypography>
              <MDButton variant="gradient" color="info" onClick={handleRoute}>
                Back Home
              </MDButton>
            </Grid>
            <Grid xs={6}>
              <img
                src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                alt=""
                width={500}
                height={250}
              />
            </Grid>
          </Grid>
        </Container>
      </MDBox>
    </PageLayout>
  );
}

export default ErrorPages;
