/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Stack } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function OrdersOverview({ orderId, button, process }) {
  return (
    <Stack direction="row" spacing={3}>
      {button && (
        <MDButton onClick={() => process(orderId)} variant="gradient" color="success" fullWidth>
          Process Order
        </MDButton>
      )}
    </Stack>
  );
}

export default OrdersOverview;
