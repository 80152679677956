/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate, useLocation } from "react-router-dom";

import { api, baseUrlApi } from "services/APIServices";
import LoadingSpin from "react-loading-spin";

// @mui material components
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import useLocalStorage from "services/useLocalstorage";

const EMAIL_REGEX = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const LOGIN_URL = "/users/login-panel";
const URL = "/system-master";
function Illustration({ action }) {
  const { setItem, getItem, removeItem } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bannerLogin, setBannerLogin] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const filter = {
    where: {
      sysCat: "frontend",
      sysSubCat: "static",
      sysCd: "banner_login",
    },
    fields: {
      sysValue: true,
    },
  };
  const fetch = async () => {
    await api
      .get(`${URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const result = res.data.data[0].sysValue;
        // console.log(result);
        setBannerLogin(baseUrlApi + result);
      });
  };

  useEffect(() => {
    fetch();
    if (action) {
      removeItem("token");
      removeItem("isOwner");
      removeItem("name");
      navigate("/login", { replace: true });
    } else if (getItem("token")) {
      setLoading(true);
      setTimeout(() => {
        navigate("/dashboards/admin-dashboard", { replace: true });
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await api
        .post(LOGIN_URL, JSON.stringify({ email, password: pwd }), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          const { data, status, message } = res.data;
          if (status) {
            setItem("token", data.token);
            setItem("isOwner", data.isOwner);
            setItem("name", data.name);

            setTimeout(() => {
              setEmail("");
              setPwd("");
              navigate("/dashboards/admin-dashboard", { replace: true });
            }, 2000);
          } else {
            setTimeout(() => {
              setErrMsg(message);
              setLoading(false);
            }, 2000);
          }
        });
    } catch (err) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={bannerLogin}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography display="block" variant="button" color="error" my={1}>
            {email && !validEmail && <p>{email} is not a valid email</p>}
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type={showPassword ? "text" : "password"}
            label="Password"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="h4" fontWeight="medium" color="error" mt={1}>
            {errMsg}
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1} textAlign="center">
          {!loading && (
            <MDButton variant="gradient" color="info" size="large" fullWidth onClick={handleSubmit}>
              sign in
            </MDButton>
          )}
          {loading && <LoadingSpin size="48px" />}
        </MDBox>
        {/* <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox> */}
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
